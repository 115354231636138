import React from "react";

const onClose = () => {
  const modal = document.getElementById("modalWindow");
  modal.style.display = "none";
};

const Modal = (props) => {

  return (
    <div id="modalWindow" className="modal-window">
      <div className="modal-window-content">
        {props.closebutton && (
          <span className="modal-window-close-btn" onClick={onClose}>
            &times;
          </span>
        )}
        {props.children}
      </div>
    </div>
  );
};
export { Modal as default };
