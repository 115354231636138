import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    return (
        <footer className='footer bg-primary py-5'>
            <div className='container cuadrilla cuadrilla-3'>
                <div>
                    <h1>ANET Cloud hosting</h1>
                    <p>Copyright &copy; 2024</p>
                </div>

                <nav>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/features'>Services</Link></li>
                        <li><Link to='/docs'>Info</Link></li>
                    </ul>
                </nav>

                <div className='social'>
                    <a href='#s232'><i className='fab fa-facebook fa-2x'></i></a>
                    <a href='#s23'><i className='fab fa-youtube fa-2x'></i></a>
                    <a href='#s23'><i className='fab fa-twitter fa-2x'></i></a>
                </div>

            </div>
        </footer>
    );
}

export { Footer as default };