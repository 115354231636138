import React, { useContext } from "react";

import { LoginContext } from "../../store/store";

import { getSum,getRound } from "../../utils/math";


const Selectdrop = () => {


    const { cartList, dispatchCart, subprods,dispatchSubprods, order, dispatchOrder } = useContext(LoginContext);

    const dropDownVisible = () => {
        const searchMenu = document.getElementById('subprod-dropdown-view')
        searchMenu.classList.toggle('subprod-dropdown-content-show');
    }
    const filterList = (e) => {
        const input = e.target;
        const filter = input.value.toUpperCase();

        const menu = document.getElementById("subprod-dropdown-view");

        const productosItems = menu.getElementsByTagName("p");

        for (let i = 0; i < productosItems.length; i++) {

            const txtValue = productosItems[i].textContent;
        
            if ((txtValue.toUpperCase().indexOf(filter) > -1) && filter) {
                productosItems[i].style.display = "block";
            } else {
                productosItems[i].style.display = "none";
            }
        }
    }

    const onAddProduct = (e) => {

        const selectedProd = subprods.find( item => item._id === e.target.id )

        let subt = getRound( getSum( cartList ) + parseFloat(selectedProd.price) );
        const subtotal =  subt;
        const tax = '0';
        const total =  subt;

        
        dispatchCart({
            type: 'ADD_TO_CART',
            _id: e.target.id,
            short_name: selectedProd.short_name,
            price: selectedProd.price
        });

        dispatchSubprods({
            type:'REMOVE_TO_PRODUCTS',
            _id: e.target.id
        });
                
        dispatchOrder({
            type: 'SET_ORDER',
            ...order,
            subtotal,
            tax,
            total
        })

    }

    return (
        <div className="subprod-dropdown">
            <button onClick={dropDownVisible}>Add</button>
            <div className="subprod-drowdown-content" id='subprod-dropdown-view'>

                <input
                    type='text'
                    placeholder="Type to search..."
                    onKeyUp={filterList}
                ></input>
                {
                    subprods.map((i, n) =>
                        <p onClick={onAddProduct}
                            id={i._id}
                            className="product-list-item" key={n}>
                            {i.short_name} / {i.price}
                        </p>)
                }
            </div>
        </div>
    );
}


export { Selectdrop as default }