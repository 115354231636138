import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { history } from "../../router/AppRouter";
import { LoginContext } from "../../store/store";

import api from "../../api/user";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { dispatchLoginInfo } =
    useContext(LoginContext);

  useEffect(() => {

    loadLogInFromLocal();
  }, [dispatchLoginInfo]);

  const loadLogInFromLocal = () => {
    const data = JSON.parse(sessionStorage.getItem("user-login"));
    if (data) {
      dispatchLoginInfo({
        type: "LOGIN",
        loggedin: true,
        currToken: data.token,
        name: data.user.name,
        lastname: data.user.lastname,
        email: data.user.email,
      });
      history.push("/dashboard");
      
    }
    
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await api.post("/users/login", {
        email,
        password,
      });

      sessionStorage.setItem("user-login", JSON.stringify(res.data));
      dispatchLoginInfo({
        type: "LOGIN",
        loggedin: true,
        currToken: res.data.token,
        name: res.data.user.name,
        lastname: res.data.user.lastname,
        email: res.data.user.email,
      });
      loadLogInFromLocal();

      if (res.data) {
        history.push("/dashboard");
      }
    } catch (e) {
      console.log(e);
      alert("Unable to login", e);
    }
  };
  return (
    <div className="showcase">
      <div className="container cuadrilla">
        <div className="showcase-texto">
          <h1>My Account</h1>
          <p className="lead">
            Host your APIs, database engines, cloud functions or any technology
            you need. We provide you roboust virtual machines for you
            development and production environments. We are also one of the most
            affodable solutions.
          </p>
        </div>
        <div className="showcase-form card">
          <form onSubmit={onSubmit}>
            <div className="form-control">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                name="email"
                placeholder="Email"
                required
              ></input>
            </div>

            <div className="form-control">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                placeholder="Password"
                required
              ></input>
            </div>
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary"
            ></input>

            <div className="form-control">
              <Link to="/">I forgot my password?</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { Login as default };
