import React from "react";

import Showcase from "./Showcase";
import Stats from "./Stats";
import Cli from "./Cli";
import Cloud from "./Cloud";
import Languages from "./Languages";

import Navbar from "./Navbar";
import Footer from "./Footer";



const Home = () => {


  return (
    <div>
      <Navbar />
      <Showcase />
      <Stats />
      <Cli />
      <Cloud />
      <Languages />
      <Footer />
    </div>
  );
};

export { Home as default };
