
const ProductsReducer = (state, action) => {
    switch(action.type){
        case 'POPULATE_PRODUCTS':
            return action.products
        case 'ADD_TO_PRODUCTS':
            return [
                ...state,
                {
                    _id: action._id,
                    short_name: action.short_name,
                    description: action.description,
                    main_product: action.main_product,
                    paypal_product_id: action.paypal_product_id,
                    subproducts: action.subproducts,
                    price: action.price
                }
            ]
        case 'REMOVE_TO_PRODUCTS':
            return state.filter( ccitem => ccitem._id !== action._id)
        default:
            return state
    }
}


export { ProductsReducer as default};
