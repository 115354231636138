import { useContext, useEffect, useState } from "react";
import { history } from "../../router/AppRouter";
import { LoginContext } from "../../store/store";
import Header from "../purchase/header";

import Loader from "../loader";
import Modal from "../purchase/modal";

const Dashboard = () => {
  const { loginInfo } = useContext(LoginContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [loginInfo]);

  const onSecurityManager = () => {
    const modal = document.getElementById("modalWindow");
    modal.style.display = "block";

    setTimeout( ()=>{
      modal.style.display = 'none'
    },2000)
  };

  const onCheckSuscriptionStatus = () => history.push("/mysuscriptions");
  const onGoToShop = () => history.push("/purchase");
  const onBilling = () => history.push("/billing");

  return (
    <div>
      <Modal closebutton={false}>
        <Loader />
      </Modal>
      <Header />
      <div className="features-main my-2">
        <div className="container cuadrilla cuadrilla-3">
          <div
            className="card flexible dashboard-item"
            onClick={onCheckSuscriptionStatus}
          >
            <i className="fas fa-satellite-dish fa-3x"></i>
            <p>Check my suscriptions services status</p>
          </div>
          <div
            className="card flexible dashboard-item"
            onClick={onSecurityManager}
          >
            <i className="fas fa-key fa-3x"></i>
            <p>
              Keep your account and data safe by updating your security measures
            </p>
          </div>
          <div className="card flexible dashboard-item" onClick={onBilling}>
            <i className="fas fa-file-invoice-dollar fa-3x"></i>
            <p>Download or query your invoices and profile for tax</p>
          </div>
          <div className="card flexible dashboard-item">
            <i className="fas fa-users fa-3x"></i>
            <p>Manage other users access to your infrastructure</p>
          </div>
          <div className="card flexible dashboard-item">
            <i className="fas fa-question-circle fa-3x"></i>
            <p>Call us now if your need technical support. Do not get stuck!</p>
          </div>

          <div className="card flexible dashboard-item" onClick={onGoToShop}>
            <i className="fas fa-shopping-cart fa-3x"></i>
            <p>See more shopping options</p>
          </div>

          <div className="card flexible dashboard-item">
            <i className="fas fa-comment fa-3x"></i>
            <p>
              Stay up to date on our terms and conditions of our services.
              Contact us if you have questions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Dashboard as default };
