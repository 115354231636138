import React from 'react';

const Cli = () => {

    return (
        <section className='cli'>
            <div className='container cuadrilla'>
                <img src='images/cli.png' alt=''></img>
                <div className='card'>
                    <h3>Easy to deploy from your local environment</h3>
                </div>
                <div className='card'>
                    <h3>Full continuous delivery and integration </h3>
                </div>
            </div>
        </section>
    );
}

export { Cli as default };