import React, { useState, useContext, useEffect } from "react";
import { LoginContext } from "../../store/store";
import Checkout from "./checkout";
import Header from "./header";
import ShopCart from "./shopcart";
import Loader from "../loader";

import api from "../../api/user";
import { getSum, getRound } from "../../utils/math";
import { formatUsdollars } from "../../utils/numberFormatter";

const Purchase = () => {
  const {
    loginInfo,
    dispatchCart,
    products,
    dispatchProducts,
    dispatchSubprods,
    order,
    dispatchOrder,
  } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const tokenString = `Bearer ${loginInfo.currToken}`;
        api.defaults.headers.common["Authorization"] = tokenString;

        const res = await api.get("/products");

        dispatchProducts({
          type: "POPULATE_PRODUCTS",
          products: res.data.filter((i) => i.main_product),
        });

        dispatchSubprods({
          type: "POPULATE_PRODUCTS",
          products: res.data.filter((i) => !i.main_product),
        });

        setLoading(false);
      } catch (err) {
        console.log(err);
        alert("Unable to fetch product data!");
      }
    };
    loadData();
  }, [loginInfo.currToken, dispatchProducts, dispatchSubprods]);

  const onSelectProduct = (e) => {
    const prodSelected = products.find((i) => i._id === e.target.id);

    dispatchCart({
      type: "POPULATE_CART",
      cart: prodSelected.subproducts,
    });

    const subtotal = getRound(getSum(prodSelected.subproducts));
    const tax = getRound(subtotal * 0);
    const total = getRound(subtotal + tax);

    dispatchOrder({
      type: "SET_ORDER",
      ...prodSelected,
      visible: true,
      quantity: 1,
      units: "pack",
      product_type: "Suscription plan",
      price: subtotal,
      subtotal,
      tax,
      total,
    });
  };

  return (
    <div>
      <Header />
      {loading && <Loader />}
      {!loading && (
        <section className="languages" id="mysuscriptions">
          <h2 className="md text-center my-2">Service Pack</h2>
          <div className="container flexible">
            {products.map((prod, i) => (
              <div className="card product" key={prod._id}>
                <h3 className="md text-left">{prod.short_name}</h3>
                <div className="text-left">
                  <span className="lg text-gray">
                    {formatUsdollars(parseFloat(prod.price))}{" "}
                  </span>
                  <span className="text-gray">/mo</span>
                  <p className="xs text-gray my-0 py-0">USD Billed Monthly </p>
                </div>

                <div className="text-left">
                  <p className="sm">{prod.description}</p>
                  <a
                    className="btn btn-dark my-1"
                    id={prod._id}
                    onClick={onSelectProduct}
                    href="#shopcart"
                  >
                    Try now
                  </a>
                  {prod.subproducts.map((subp, i) => {
                    if (subp.show)
                      return (
                        <p key={i} className="xs2 my-0">
                          + {subp.description}
                        </p>
                      );
                  })}
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      {order.visible && <ShopCart />}
      {order.visible && <Checkout />}
    </div>
  );
};

export { Purchase as default };
