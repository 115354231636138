
import React, { useState, useContext } from 'react';
import Loader from '../loader';
import { LoginContext } from '../../store/store';
import { Link } from 'react-router-dom';
import { history } from '../../router/AppRouter';

import api from '../../api/user';

const Signup = () => {

    const [loading, setLoading] = useState(false);
    const [confirmCode, setConfirmCode] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const { dispatchLoginInfo } = useContext(LoginContext);

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordB, setPasswordB] = useState('');
    const [error, setError] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();

        if (password !== passwordB) {
            setError('Please verify password match!')
            return;
        }

        if (password.length < 6) {
            setError('Password has to be at least 6 characters');
            return;
        }

        setError('');
        setLoading(true);

        setTimeout(async () => {

            try {

                setLoading(false);

                setPassword('');
                setPasswordB('');
                setSubmitted(true);

                await api.post('/users/signup', {
                    name,
                    lastname,
                    email,
                    password
                })

            }
            catch (e) {
                console.log(e);
                setSubmitted(false);
                setError('Unable to signup, probably this email has been already registered');
            }

        }, 3000)


    }

    const onValidateCode = async (e) => {
        e.preventDefault();
       
        setLoading(true);
        setError('');
            setTimeout(async () => {

                try {

                    const res = await api.post(`/users/create/${confirmCode}`);
                    dispatchLoginInfo({
                        type: 'LOGIN',
                        loggedin: true,
                        name: res.data.user.name,
                        lastname: res.data.user.lastname,
                        email: res.data.user.email,
                        currToken: res.data.token
                    });
                    
                    setLoading(false);
                    history.push('/dashboard');
                }
                catch(e){
                    setLoading(false);
                    setError('Unable to validate your request, either your confirmation code has expired or is invalid..')        
                }

            }, 3000)


        }



    return (
        <div className='showcase'>
                    <div className='container cuadrilla'>
                        <div className='showcase-texto'>
                            <h1>Create Account</h1>
                            <p className='lead'>
                                Host your APIs, database engines, cloud functions or any technology you need. We provide you roboust virtual machines for you development and production environments. We are also one of the most affodable solutions.
                            </p>
                        </div>
                        <div className='showcase-form card'>

                            {
                                submitted ?
                                    <div>
                                        {error && 
                                        <div className='alert alert-success'>
                                            <i className='fas fa-info'></i>
                                            {error}
                                        </div>}
                                        <p>An confirmation code has been sent to your email account, please copy and paste the code here</p>
                                        <div className='form-control'>
                                            <input
                                                value={confirmCode}
                                                onChange={e => setConfirmCode(e.target.value)}
                                                type='text' name='confirmconde' placeholder='Confirmation code' required></input>
                                        </div>
                                        
                                        { !loading && <button className='btn btn-primary' onClick={onValidateCode}>Validate
                                        </button> }
                                        { loading && <Loader />}
                                        
                                        <div className='form-control'>
                                            <a onClick={() => setSubmitted(false)} href='#cd'>I did not received the code</a>
                                        </div>
                                    </div> :
                                    <form onSubmit={onSubmit}>
                                        {error && <div className='alert alert-success'>
                                            <i className='fas fa-info'></i>
                                            {error}
                                        </div>}
                                        <div className='form-control'>
                                            <input
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                type='text' name='name' placeholder='Name' required></input>
                                        </div>

                                        <div className='form-control'>
                                            <input
                                                value={lastname}
                                                onChange={e => setLastname(e.target.value)}
                                                type='text' name='lastname' placeholder='Last Name' required></input>
                                        </div>

                                        <div className='form-control'>
                                            <input
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                type='text' name='email' placeholder='Email' required></input>
                                        </div>

                                        <div className='form-control'>
                                            <input
                                                value={password}
                                                onChange={e => setPassword(e.target.value)}
                                                type='password' name='password' placeholder='Password' required></input>
                                        </div>

                                        <div className='form-control'>
                                            <input
                                                value={passwordB}
                                                onChange={e => setPasswordB(e.target.value)}
                                                type='password' name='confirm-password' placeholder='Confirm password' required></input>
                                        </div>

                                        { !loading &&
                                        <input
                                            type='submit' value='Submit' className='btn btn-primary'>    
                                        </input> }
                                        { loading && <Loader />}
                                        <div className='form-control'>
                                            
                                            <Link to='/login'>Already have an account?</Link>
                                        </div>

                                    </form>}
                        </div>
                    </div>
            { loading && <Loader />}
        </div>
    );
}

export { Signup as default };