import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../components/home/Navbar';
import Footer from '../../components/home/Footer';

const Hosting = () => {

    return (
        <div>
            <Navbar />
            <div className='docs-head bg-primary py-3'>
                <div className='container cuadrilla'>
                    <div>
                        <h1 className='xl'>On-Premise Hosting Services</h1>
                        <p className='lead'>
                        To register your database, enter your subscription code in the banner in the app dashboard. If the registration is successful, the banner will turn green and display the database expiration date.                        </p>
                    </div>
                    <img src='images/docs.png' alt=''></img>
                </div>
            </div>

            <div className='docs-main my-2 mx-2'>

                {/* <div className='container cuadrilla'> */}
                {/* <div className='card bg-light p-3'>
                        <h3 className='my-2'>Essentials</h3>
                        <nav>
                            <ul>
                                <li><p>Intro</p></li>
                                <li><p>Components</p></li>
                                <li><p>Redux</p></li>
                            </ul>
                        </nav>
                        <h3 className='my-2'>Deployment</h3>

                        <nav>
                            <ul>
                                <li><p>Heroku</p></li>
                                <li><p>Google Cloud Platform</p></li>
                                <li><p>Netlify</p></li>
                            </ul>
                        </nav>
                        <h3 className='my-2'>Management</h3>
                        <nav>
                            <ul>
                                <li><p>Change management</p></li>
                                <li><p>24 hours support</p></li>
                                <li><p>Infrastructure optimization</p></li>
                            </ul>
                        </nav>
                    </div> */}
                <div className='card'>
                    <h2>Setup guide</h2>
                    <p>
                    Depending on the intended use case, there are multiple ways to install ANET Cloud Services. For developers of the ANET community alike, the preferred way is to perform a source install (running ANET Cli).
                        <ul>
                            <li><p>* For customers who purchase or renew a subscription (including free trials) online from ANET Cloud, your use is governed by either the ANET Cloud Customer Agreement ("ANCCA"), or the ANET Online Subscription Agreement ("ANOSA"). Your use is governed by the latter if the ANCCA is not available in your geography. Visit the ANCCA page for availability details.</p></li>
                            <li><p>* For customers who purchase through another licenses, such as an Enterprise Agreement, your use is governed by the licensing agreement under which you purchased the services. You can obtain a copy of your licensing agreement by contacting your ANET Cloud account representative or Commercial Licensing.</p></li>
                            <li><p>* If you do not have an ANET Cloud subscription, our Terms of Use will govern your use of the limited ANET Cloud services which can be used without a subscription.</p></li>
                        </ul>        
                    </p>
                    <div className='alert alert-success'>
                        <i className='fas fa-info'></i>
                        If you are uncertain or have other questions, please contact ANET Cloud at contact@aristanetworks.global
                    </div>
                    <h3>Privacy Statement</h3>
                    

                    <p>Read ANET's Privacy Statement you can find the personal data ANET Cloud processes, how ANET Cloud processes it, and for what purposes. Your applicable Services Agreement or the Preview Supplemental Terms may specify lesser or different privacy measures for some Preview services.</p>

                    <Link className='btn btn-primary' to='/signup'>Sign Up</Link>
                    {/* <h3>Requierements</h3>
                        <ul>
                            <li>Windows 10, Mac OSX, Linux</li>
                            <li>Node.js V12 or Higher</li>
                        </ul>
                        <h3>Install</h3>
                        <p>Mac (homebrew)</p>
                        <pre> <code>$ brew install arista-net</code></pre>
                        <p>NPM</p>
                        <pre> <code>$ npm install arista-net</code></pre>
                        <p>Yarn</p>
                        <pre> <code>$yarn install arista-net</code></pre> */}
                </div>
                {/* </div> */}

            </div>

            <Footer />
        </div>
    );
}

export { Hosting as default };