import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import { LoginContext } from '../store/store';

const PublicRoute = ({component: Component, ...rest}) => {
    const { loginInfo } = useContext( LoginContext);
    return(
        <Route
        
            {...rest}
            render={ (props) =>{
                
                return !loginInfo.loggedin ? <Component {...props} />
                                    : <Redirect to={ {pathname :"/"}} />
                
            }}
         
        />
    );
}

export { PublicRoute as default };