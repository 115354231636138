import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../store/store";
import { formatUsdollars } from "../../utils/numberFormatter";
import api from '../../api/user';

const Mysuscriptions = () => {
  const { order, loginInfo } = useContext(LoginContext);

  const [subscriptions, setSubscriptions] = useState([]);


  useEffect(() => {

    const onLoadData = async () => {
        try{
          const tokenString = `Bearer ${loginInfo.currToken}`;
          api.defaults.headers.common["Authorization"] = tokenString;
          const res = await api.get(`/users/orders`);

          const newData = res.data.filter( (i) => !!i.subscription);

          const data = newData.map( item =>( {
            product: item.cart.product,
            summary: item.cart.summary,
            subscriptionId: item.subscription.subscriptionID
          }));
          setSubscriptions(data);
        
        }
        catch(e){
          console.log(e);
          alert('Not able to retrieve subscription data!');
        }
    }

    onLoadData();


  }, [loginInfo.currToken]);

  return (
    <section>

      {
        subscriptions.map( subs => (
        <div className="shopcart card" id="shopcart">
        <h3 className="text-center">Subscription {subs.subscriptionId} </h3>
        <div className="chartcontent">
          <div className="rowitm">
            <p>Item</p>
            <p>{subs.product.short_name} App Hosting Pack</p>
          </div>

          <div className="rowitm">
            <p>Description</p>
            <p>{subs.product.description}</p>
          </div>

          <div className="rowitm">
            <p>Type</p>
            <p>{subs.product.product_type}</p>
          </div>

          <div className="rowitm">
            <p>Quantity</p>
            <p>
              {subs.product.quantity} {order.units}
            </p>
          </div>

        </div>
        <h3>Price Summary</h3>
        <div className="chartcontent">
          <div className="rowitm">
            <p>Subtotal</p>
            <p>{formatUsdollars(subs.summary.subtotal)} usd</p>
          </div>

          <div className="rowitm">
            <p>Tax</p>
            <p>{formatUsdollars(subs.summary.tax)} usd</p>
          </div>

          <div className="rowitm">
            <p>Total</p>
            <p>{formatUsdollars(subs.summary.total)} usd</p>
          </div>

          <a className="btn btn-dark my-1 my-1" href="#checkout">
            Cancel Suscription
          </a>
        </div>
      </div>))
      }
      
    </section>
  );
};

export { Mysuscriptions as default };
