import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

    return (
        <div className='navbar'>
            <div className='container flexible'>
                <h1 className='logo'>ANET Cloud</h1>
                <nav>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/features'>Services</Link></li>
                        <li><Link to='docs'>Info</Link></li>
                    </ul>
                </nav>
            </div>

        </div>
    );
}

export { Navbar as default };