import React from "react";

const Loader = () => {

  return (
    
      <div className="loader-container">
        <div className="loader">
          <div className="blob1"></div>
          <div className="blob2"></div>
          <div className="blob3"></div>
        </div>
        <p>Processing....</p>
      </div>

  );
};

export { Loader as default };
