import { useState, useEffect, useContext } from "react";
import { LoginContext } from "../../store/store";
import  { Link } from 'react-router-dom';
import { formatUsdollars } from '../../utils/numberFormatter'
import { formatLocalDate } from "../../utils/dateFormatter";

import api from "../../api/user";


const Billing = () => {
  const [charges, setCharges] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState('')
  const { loginInfo } = useContext(LoginContext);


  useEffect(() => {

    const onLoadData = async () => {
      try {
        const tokenString = `Bearer ${loginInfo.currToken}`;
        api.defaults.headers.common['Authorization'] = tokenString;

        const res = await api.get('/users/orders');
        const orders = res.data.filter( (i) => !!i.subscription);
        
        if ( orders.length ){
          setSubscriptionId( orders[(orders.length)-1].subscription.subscriptionID );
          const res = await api.get(`/users/transactions/${orders[(orders.length)-1].subscription.subscriptionID}`);
          setCharges(res.data.transactions);
        }
      }
      catch(e){
        console.log(e)
        alert('Unable to load billing info... see console for detailed errors at api request');
      }
    }

    onLoadData(); 

  }, [loginInfo]);


  return (
    <section>
      <h1 className="md text-center y1">Charges & Billing</h1>
      <div className="shopcart card bill-table">
          
          <div className="bill-table-header">
              <p>Date</p>
              <p>ID</p>
              <p className="description">Sent to:</p>
              <p>Amount</p>
              <p>Download</p>
          </div>

          <div className="bill-table-content">

          {
            charges.map( ch =>
              <div className="bill-table-row" key={ch.id}>
              <p>{formatLocalDate(ch.time) }</p>
              <p>{ch.id}</p>
              <p className="description">{ch.payer_name.given_name} {ch.payer_name.surname} ({ch.payer_email})</p>
              <p>{formatUsdollars(parseFloat(ch.amount_with_breakdown.gross_amount.value))}</p>
              <p><Link to={`/invoice/${subscriptionId}/${ch.id}/${loginInfo.currToken}`} target='_blank'><i className="fas fa-download fa-1x"></i></Link></p>
            </div> )
          }



          </div>


      </div>

    </section>
  );
};

export { Billing as default };
