const LoginReducer = (state, action) => {
    switch(action.type){
        case 'LOGIN': 
            return {
                ...state,
                loggedin: action.loggedin,
                name: action.name,
                lastname: action.lastname,
                email: action.email,
                currToken: action.currToken
            }

        default:
            return state;
    }
}

export { LoginReducer as default };