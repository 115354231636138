import React from 'react';

const Head = () => {

    return (
        <div>
            <div className='features-head bg-primary py-3'>
                <div className='container cuadrilla'>
                    <div>
                        <h1 className='xl'>Services</h1>
                        <p className='lead'>
                        Adopt the right cloud strategies to meet your specific business, industry, and security needs. Define a clear path forward with proven guidance and a collection of best practices, programs, and learning resources for your entire organization to build expertise and achieve ongoing cloud value.
                    </p>
                    </div>
                    <img src='images/server.png' alt=''></img>
                </div>
            </div>
            <div className='features-subhead bg-light py-3'>
                <div className='container cuadrilla'>
                    <div>
                        <h1 className='md'>Focus on productivity</h1>
                        <p>
                        Achieve your goals with the freedom and flexibility to build, manage, and deploy your applications anywhere. Use your preferred languages, frameworks, and infrastructure—even your own datacenter and other clouds—to solve challenges large and small.
                    </p>
                    </div>
                    <img src='images/server2.png' alt=''></img>
                </div>
            </div>

            <div className='features-main my-2'>
                <div className='container cuadrilla cuadrilla-3'>
                    <div className='card flexible'>
                        <i className='fas fa-server fa-3x'></i>
                        <p>Take advantage of multilayered security across physical datacenters, infrastructure, and operations with ANET Cloud
                        </p>
                    </div>
                    <div className='card flexible'>
                        <i className='fas fa-database fa-3x'></i>
                        <p> Help protect your business assets and data with industry-leading physical security systems and a comprehensive portfolio of compliance offerings and privacy standards.
                        </p>
                    </div>
                    <div className='card flexible'>
                        <i className='fas fa-power-off fa-3x'></i>
                        <p>Learn how ANET Cloud is improving the performance, efficiency, power consumption, and costs of our datacenters for your cloud workloads
                        </p>
                    </div>
                    <div className='card flexible'>
                        <i className='fas fa-laptop fa-3x'></i>
                        <p>Accelerate developer creativity and time to market by removing friction through Developer Velocity.
                        </p>
                    </div>
                    <div className='card flexible'>
                        <i className='fas fa-server fa-3x'></i>
                        <p>We provide enterprise-grade cloud infrastructure on which customers and partners can rely.
                        </p>
                    </div>
                    <div className='card flexible'>
                        <i className='fas fa-server fa-3x'></i>
                        <p>Redundant power, networking, and cooling and software elements like safe deployment processes, impactless maintenance, and failure prediction are enabled.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );

}

export { Head as default };