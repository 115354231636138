import React, { useEffect, useContext } from 'react';

import { history } from '../../router/AppRouter';
import { LoginContext } from '../../store/store';

const Header = () => {

    const { loginInfo,dispatchLoginInfo } = useContext(LoginContext);


    const onLogout = () => {
        sessionStorage.removeItem("user-login");
        dispatchLoginInfo({
            type: 'LOGIN',
            loggedin: false,
            currToken: '',
            name: '',
            lastname: '',
            email: ''

        });
        history.push('/login');
    }

    const handlClick = e => {

        // comprueba si el click corresponde al elemento con el data-dropdown-button
        const isDropdownButton = e.target.matches("[data-dropdown-button]") ;
        
        // con esta linea ignora por completo el click, ya que significa que es dentro de un dropdown
        // valida si no es click sobre el data-dropdown-button && el click cae fuera de recuadro data-dropdown
        const closeDropdown = e.target.closest("[data-dropdown]");

        
        if( !isDropdownButton && closeDropdown != null ) {
            return;
        }


        /// buscar el dropdown activo y lo deja activo
        let currentDropdown;

        if( isDropdownButton ){
            currentDropdown = e.target.closest("[data-dropdown]") ;
            currentDropdown.classList.toggle("active");
        }

        document.querySelectorAll("[data-dropdown].active").forEach( dropdown => {
                if( dropdown === currentDropdown ) return;
                dropdown.classList.remove("active");
        })
    }

    useEffect( ()=> {
        // En cada click en el body principal
        window.addEventListener("click", handlClick);

        return () => {
            window.removeEventListener("click",handlClick);
        }


    },[]);


    return (
        <header className="header">
            <div className="dropdown" data-dropdown>
                <p className="link" >
                <i className="fas fa-user-circle fa-2x" data-dropdown-button></i>
                </p>
                <div className="dropdown-menu">
                    <a className="link" href='#mysuscriptions'>My Suscriptions</a>
                    <a className="link" href="#mypaymentmethods">My payment methods</a>
                    <a className="link" href="#myinvoices">My Invoices</a>
                    <a className="link" href="#myprofile">My profile</a>
                    <a className="link" href="#logout" onClick={onLogout}>Log out</a>
                </div>
            </div> 
            
            <p>Hi! {loginInfo.name}</p>
        </header>
    );

}

export { Header as default };