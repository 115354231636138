import React from 'react';

const Cloud = () => {

    return (
        <section className='cloud bg-primary my-2 py-2'>
            <div className='container cuadrilla'>
                <div className='text-center'>
                    <h2 className='lg'>About managed infrastructure</h2>
                    <p className='lead my-1'>High realiability has never been that close and affordable. Just request a demo and we'll get back to you inmediately</p>
                    <a href='http://www.arista.com' className='btn btn-outline'>Read more</a>
                </div>
                <img src='images/cloud.png' alt=''></img>
            </div>
        </section>
    );
}

export { Cloud as default };