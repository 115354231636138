import React, { useContext } from "react";
import Selectdrop from "./selectdrop";
import { LoginContext } from "../../store/store";

import { formatUsdollars } from "../../utils/numberFormatter";
import { getRound, getSum } from "../../utils/math";

const ShopingCart = () => {
  const { cartList, dispatchCart, dispatchSubprods, dispatchOrder, order } =
    useContext(LoginContext);

  const onRemoveFromCart = (e) => {
    const _id = e.target.id;

    const selectedProd = cartList.find((item) => item._id === _id);

    dispatchCart({
      type: "REMOVE_TO_CART",
      _id,
    });

    dispatchSubprods({
      type: "ADD_TO_PRODUCTS",
      _id,
      short_name: selectedProd.short_name,
      price: selectedProd.price,
    });

    let subt = getRound(getSum(cartList) - parseFloat(selectedProd.price));

    const subtotal = subt;
    const tax = "0";
    const total = subt;
    dispatchOrder({
      type: "SET_ORDER",
      ...order,
      subtotal,
      tax,
      total,
    });
  };


  return (
    <section>

      <div className="shopcart card" id="shopcart">
        <h3 className="text-center">My Orden</h3>
        <div className="chartcontent">
          <div className="rowitm">
            <p>Item</p>
            <p>{order.short_name} App Hosting Pack</p>
          </div>

          <div className="rowitm">
            <p>Description</p>
            <p>{order.description}</p>
          </div>
          <div className="rowitm">
            <p>Pack Services</p>
            <ul>
              {cartList.map((i, n) => (
                <li key={n}>
                  <i
                    className="fas fa-eraser"
                    onClick={onRemoveFromCart}
                    id={i._id}
                  ></i>
                  {i.short_name} {formatUsdollars(parseFloat(i.price))}
                </li>
              ))}

              <li className="select-drop-container">
                <Selectdrop />
              </li>
            </ul>
          </div>

          <div className="rowitm">
            <p>Type</p>
            <p>{order.product_type}</p>
          </div>

          <div className="rowitm">
            <p>Quantity</p>
            <p>
              {order.quantity} {order.units}
            </p>
          </div>

          <div className="rowitm">
            <p>Base Price</p>
            <p>{formatUsdollars(parseFloat(order.price))} USD</p>
          </div>
        </div>
        <h3>Order Summary</h3>
        <div className="chartcontent">
          <div className="rowitm">
            <p>Subtotal</p>
            <p>{formatUsdollars(parseFloat(order.subtotal))} USD</p>
          </div>

          <div className="rowitm">
            <p>Tax</p>
            <p>{formatUsdollars(parseFloat(order.tax))} USD</p>
          </div>

          <div className="rowitm">
            <p>Total</p>
            <p>{formatUsdollars(parseFloat(order.total))} USD</p>
          </div>

        </div>
      </div>
    </section>
  );
};

export { ShopingCart as default };
