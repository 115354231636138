import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../components/home/Navbar';
import Footer from '../../components/home/Footer';

const Docs = () => {

    return (
        <div>
            <Navbar />
            <div className='docs-head bg-primary py-3'>
                <div className='container cuadrilla'>
                    <div>
                        <h1 className='xl'>Sign Up</h1>
                        <p className='lead'>
                            Try ANET Cloud free—then only pay for what you use.
                            After your $200 credit, only pay for what you use beyond the free amounts of services. With pay as you go, there’s no fee to maintain your ANET free account, and no upfront commitment. Cancel anytime.
                        </p>
                    </div>
                    <img src='images/docs.png' alt=''></img>
                </div>
            </div>
            
            <div className='docs-main my-2 mx-2'>
            <Link className='btn btn-primary' to='/signup'>Sign Up</Link>
                {/* <div className='container cuadrilla'> */}
                {/* <div className='card bg-light p-3'>
                        <h3 className='my-2'>Essentials</h3>
                        <nav>
                            <ul>
                                <li><p>Intro</p></li>
                                <li><p>Components</p></li>
                                <li><p>Redux</p></li>
                            </ul>
                        </nav>
                        <h3 className='my-2'>Deployment</h3>

                        <nav>
                            <ul>
                                <li><p>Heroku</p></li>
                                <li><p>Google Cloud Platform</p></li>
                                <li><p>Netlify</p></li>
                            </ul>
                        </nav>
                        <h3 className='my-2'>Management</h3>
                        <nav>
                            <ul>
                                <li><p>Change management</p></li>
                                <li><p>24 hours support</p></li>
                                <li><p>Infrastructure optimization</p></li>
                            </ul>
                        </nav>
                    </div> */}
                <div className='card'>
                    <h2>Service Agreement & Terms</h2>
                    <p>
                        Your use of the ANET service is governed by the terms and conditions of the agreement under which you obtained the services.
                        <ul>
                            <li><p>* For customers who purchase or renew a subscription (including free trials) online from ANET Cloud, your use is governed by either the ANET Cloud Customer Agreement ("ANCCA"), or the ANET Online Subscription Agreement ("ANOSA"). Your use is governed by the latter if the ANCCA is not available in your geography. Visit the ANCCA page for availability details.</p></li>
                            <li><p>* For customers who purchase through another licenses, such as an Enterprise Agreement, your use is governed by the licensing agreement under which you purchased the services. You can obtain a copy of your licensing agreement by contacting your ANET Cloud account representative or Commercial Licensing.</p></li>
                            <li><p>* If you do not have an ANET Cloud subscription, our Terms of Use will govern your use of the limited ANET Cloud services which can be used without a subscription.</p></li>
                        </ul>        
                    </p>
                    <div className='alert alert-success'>
                        <i className='fas fa-info'></i>
                        If you are uncertain or have other questions, please contact ANET Cloud at contact@aristanetworks.global
                    </div>
                    <h3>Privacy Statement</h3>
                    

                    <p>Read ANET's Privacy Statement you can find the personal data ANET Cloud processes, how ANET Cloud processes it, and for what purposes. Your applicable Services Agreement or the Preview Supplemental Terms may specify lesser or different privacy measures for some Preview services.</p>
                    <Link className='btn btn-primary' to='/sla'>Read SLA</Link>
                    
                    {/* <h3>Requierements</h3>
                        <ul>
                            <li>Windows 10, Mac OSX, Linux</li>
                            <li>Node.js V12 or Higher</li>
                        </ul>
                        <h3>Install</h3>
                        <p>Mac (homebrew)</p>
                        <pre> <code>$ brew install arista-net</code></pre>
                        <p>NPM</p>
                        <pre> <code>$ npm install arista-net</code></pre>
                        <p>Yarn</p>
                        <pre> <code>$yarn install arista-net</code></pre> */}
                </div>
                {/* </div> */}

            </div>

            <Footer />
        </div>
    );
}

export { Docs as default };