
const CartReducer = (state, action) => {
    switch(action.type){
        case 'POPULATE_CART':
            return action.cart
        case 'ADD_TO_CART':
            return [
                ...state,
                {
                    _id: action._id,
                    short_name: action.short_name,
                    price: action.price
                }
            ]
        case 'REMOVE_TO_CART':
            return state.filter( ccitem => ccitem._id !== action._id)
        default:
            return state
    }
}


export { CartReducer as default};
