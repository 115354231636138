const OrderReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ORDER':

            return {
                ...state,
                visible: action.visible,
                short_name: action.short_name,
                description: action.description,
                quantity: action.quantity,
                units: action.units,
                product_type: action.product_type,
                price: action.price,
                subtotal: action.subtotal,
                tax: action.tax,
                total: action.total,
                active: action.active,
                main_product: action.main_product,
                paypal_product_id: action.paypal_product_id,
                subproducts: action.subproducts
            }

        default:
            return state;
    }
}

export { OrderReducer as default };