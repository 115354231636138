import React from 'react';

const Languages = () => {

    return (
        <section className='languages'>
            <h2 className='md text-center my-2'>Supported technologies</h2>
            <div className='container flexible'>
                <div className='card'>
                    <h4>Node.js</h4>
                    <img src='images/logos/node.png' alt=''></img>
                </div>
                <div className='card'>
                    <h4>Go</h4>
                    <img src='images/logos/go.png' alt=''></img>
                </div>
                <div className='card'>
                    <h4>C#</h4>
                    <img src='images/logos/csharp.png' alt=''></img>
                </div>
                <div className='card'>
                    <h4>Clojure</h4>
                    <img src='images/logos/clojure.png' alt=''></img>
                </div>
                <div className='card'>
                    <h4>Python</h4>
                    <img src='images/logos/python.png' alt=''></img>
                </div>
                <div className='card'>
                    <h4>Scala</h4>
                    <img src='images/logos/scala.png' alt=''></img>
                </div>
            </div>
        </section>
    );
}

export { Languages as default };