import React from 'react';
import {Link  } from 'react-router-dom';

const Showcase = () => {

    return (
        <div className='showcase'>
            <div className='container cuadrilla'>
                <div className='showcase-texto'>
                    <h1>Cutting Edge App Hosting</h1>
                    <p className='lead'>
                        Host your APIs, database engines, cloud functions or any technology you need. We provide you roboust virtual machines for you development and production environments. We are also one of the most affodable solutions.
                    </p>
                    <Link to='/hosting' className='btn btn-outline'>Read more</Link>
                </div>
                <div className='showcase-form card'>
                    <h2>Request a quote!</h2>
                    <form id='showcase-form' name="contacto-form" method="POST" netlify-honeypot="bot-field" data-netlify="true">

                        <input type="hidden" name="contacto-form" value="contact" />
                        <p className="hidden">
                            <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                        </p>

                        <div className='form-control'>
                            <input type='text' name='name' placeholder='Full Name' required></input>
                        </div>
                        <div className='form-control'>
                            <input type='text' name='company' placeholder='Organization' required></input>
                        </div>
                        <div className='form-control'>
                            <input type='text' name='correo' placeholder='Email' required></input>
                        </div>
                        <input type='submit' value='Submit' className='btn btn-primary'></input>
                    </form>
                </div>
            </div>
        </div>
    );
}
export { Showcase as default }