import React from 'react';

import Navbar from '../../components/home/Navbar';
import Footer from '../../components/home/Footer';
import Head from './Head';

const Features = () => {

    return (
        <div>
            <Navbar />
            <Head />            
            <Footer />
        </div>
    );
}

export { Features as default };