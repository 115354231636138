import React, { createContext, useReducer } from "react";
import LoginReducer from '../reducer/login';
import CartReducer from '../reducer/cart';
import ProductsReducer from '../reducer/products';
import OrderReducer from '../reducer/order';

const loginInitState = {
    loggedin: false,
    name: '',
    lastname: '',
    email: '',
    currToken: ''
}
const orderInit = {
    visible: false,
    short_name: '',
    description: '',
    quantity: 0,
    units: 'pack',
    product_type: 'Suscription plan',
    subtotal: '0',
    tax: '0',
    total: '0',
    active: true,
    main_product: true
}


export const LoginContext = createContext();

const Store = ({ children }) => {
    const [loginInfo, dispatchLoginInfo] = useReducer(LoginReducer, loginInitState);
    const [order, dispatchOrder] = useReducer(OrderReducer, orderInit)
    
    const [cartList, dispatchCart] = useReducer(CartReducer, []);
    const [products, dispatchProducts] = useReducer(ProductsReducer, []);

    const [subprods, dispatchSubprods] = useReducer(ProductsReducer, []);

    return (
        <LoginContext.Provider value={{ loginInfo, dispatchLoginInfo, cartList, dispatchCart, products,dispatchProducts, subprods, dispatchSubprods, order, dispatchOrder }}>
            {children}
        </LoginContext.Provider>
    );

}


export default Store;