export const getSum = (products) => {
    let sum = 0
    for (let i = 0; i < products.length; i++) {
        sum = sum + parseFloat(products[i].price)
    }
    return sum;

}

export const getRound = (number) => {
    const factor = Math.pow(10, 1);
    return Math.round( number * factor ) / factor

}